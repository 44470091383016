<template>
  <v-container class="pa-0">

    <v-card-title>
      <v-icon left large color="primary">
        mdi-credit-card
      </v-icon>
      Pago con Tarjeta de Crédito
    </v-card-title>
    <v-stepper v-model="e6" vertical>
      <v-stepper-step class="pl-2" :complete="e6 > 1" step="1">
        Selecciona tu plan.
        <!-- <small>Summarize if needed</small> -->

        <!--Show selected daysxmonths-->
        <template v-if="e6 > 1">
          <v-chip class="ml-2" color="primary darken-2" small>
            {{ selectedDays > 6 ? 'Pase libre' : selectedDays + (selectedDays > 1 ? ' Días' : ' Día') }} x {{
              selectedMonths }} {{ selectedMonths > 1 ? 'Meses' : 'Mes' }}
          </v-chip>
        </template>
      </v-stepper-step>

      <v-stepper-content step="1" class="mx-2 pa-0 pt-2" style="border:0px !important">

        <v-skeleton-loader type='image' v-if="loading"></v-skeleton-loader>
        <v-form ref="form" v-else>



          <v-simple-table v-for="(item, index) in subscriptions" :key="index + 'sus'">
            <thead>
              <tr>
                <th class="px-2">Meses</th>

                <th v-for="(day, index) in days" :key="'day-header-' + index" v-if="checkIfDisplay(item, day, index)">


                  <v-radio-group v-model="selectedDays" dense hide-details="" :rules="requiredRules" class="mt-0">
                    <v-radio :key="index + 1"
                      :label="` ${index + 1 > 6 ? 'Pase libre' : index + 1}   ${day > 0 && day <= 6 ? (day > 1 ? 'Días' : 'Día') : ''}`"
                      :value="index + 1"></v-radio>
                  </v-radio-group>

                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, rowIndex) in item.priceMatrix" :key="'row-' + rowIndex">
                <td class="px-2 pb-4">
                  <v-radio-group v-model="selectedMonths" dense hide-details="" :rules="requiredRules"
                    @change="updateInstallments">
                    <v-radio :key="rowIndex" :label="row[0].months + ''" :value="row[0].months"></v-radio>

                  </v-radio-group>




                </td>
                <td v-for="(cell, cellIndex) in row.slice(1)" :key="'cell-' + cellIndex" v-if="cell.price > 0"
                  @click="selectDaysAndMonths(cellIndex, rowIndex)" v-ripple class="text-center"
                  v-bind:class="{ 'active': selectedDays === cellIndex + 1 && selectedMonths === row[0].months }">
                  ${{ cell.price }}
                  <span v-if="rowIndex > 0 && cell.price && cell.price > 0">
                    <!--      Precio sin descuento {{ (item.priceMatrix[0][cellIndex+1].price * row[0].months )}} 
               Descuento total: {{  cell.price - (item.priceMatrix[0][cellIndex+1].price * row[0].months) }} -->

                    <v-chip color="success" small class="ml-1">
                      -{{ (100 - (cell.price / (item.priceMatrix[0][cellIndex + 1].price * row[0].months) *
                        100)).toFixed(2) }}%
                    </v-chip>
                  </span>
                </td>
              </tr>
            </tbody>
          </v-simple-table>


          <v-autocomplete v-if="selectedMonths && selectedDays" v-model="installments" filled rounded class="mt-4"
            append-icon="mdi-cash-multiple" :items="installmentsItems" label="Cantidad de Cuotas" hide-details disabled
            :rules="requiredRules"></v-autocomplete>


        </v-form>

        <v-card-actions class="mb-6 mt-2">

          <v-btn block color="primary" @click="confirmSelection" rounded large>
            <v-icon left>
              mdi-check
            </v-icon>
            Confirmar Seleccion
          </v-btn>

        </v-card-actions>
      </v-stepper-content>

      <v-stepper-step class="pl-2" id="step2" :complete="e6 > 2" step="2">
        Ingresa tus datos de pago
        <v-btn @click="goBack" large class="mx-1" text color="primary" v-if="e6 > 1">
          <v-icon left>
            mdi-arrow-left
          </v-icon>
          Volver
        </v-btn>
      </v-stepper-step>

      <v-stepper-content step="2" class="ml-1 mb-12">

        <p class=" text-h6" v-if="selectedDays && selectedMonths">
          Total a pagar: ${{ subscription.priceMatrix.find(row => row[0].months === selectedMonths)[selectedDays].price
          }} en
          {{ installments }} {{ installments > 1 ? 'cuotas' : 'cuota' }}.
        </p>

        <div id="cardPaymentBrick_container"></div>
        <p class="caption mt-2 text-center">
          La información de tu tarjeta de crédito está segura y no será almacenada en nuestros servidores.
          El pago se procesará a través de MercadoPago.
        </p>

      </v-stepper-content>


    </v-stepper>




    <div id="script_container"></div>

    <!--     <div id="cardPaymentBrick_container"></div>
 -->
    <!--    <div id="wallet_container"></div> -->



    <!-- Loading v-overlay -->
    <v-overlay v-model="loadingPayment">
      <v-progress-circular size="150" indeterminate color="primary" width="7">
      </v-progress-circular>
    </v-overlay>

  </v-container>
</template>

<script>

//import firebase functions
import { getFunctions, httpsCallable } from "firebase/functions";

//import firestore adddoc doc collection,etc
import { getFirestore, collection, addDoc, setDoc, getDoc, getDocs, updateDoc, doc, query, where } from "firebase/firestore";
import moment from 'moment';
import { install } from "vuex";


export default {
  name: 'MercadoPago',
  data() {
    return {
      selectedMonths: null,
      selectedDays: null,
      days: [1, 2, 3, 4, 5, 6, 'Libre'],
      loadingPayment: false,
      mp: null,
      bricksBuilder: null,
      e6: 1,
      subscriptions: [],
      loading: false,
      subscription: null,
      requiredRules: [
        v => !!v || 'Este campo es requerido',
      ],
      loading: true,
      installments: 1,
      installmentsItems: [],
      noCredentials: false,
      mercadoPagoConfiguration: null,
    };
  },
  created() {

  },
  mounted() {

    this.fetchSubscriptionsForUserType();

    const script = document.createElement('script');
    script.src = 'https://sdk.mercadopago.com/js/v2';
    script.async = true;
    script.onload = async () => {

      try {
        await this.checkCredentials();
        if (this.noCredentials) {
          this.$router.push('/fallido')
        } else {

          this.mp = new MercadoPago(this.mercadoPagoConfiguration.publicKey);
          this.bricksBuilder = this.mp.bricks();
        }
      }
      catch (e) {
        console.error(e);
        this.$router.push('/fallido')
      }

    };



    document.getElementById('script_container').appendChild(script);

  },
  methods: {
    async checkCredentials() {
      const db = getFirestore();
      const docRef = doc(db, "configurations", "mercadopago");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        this.noCredentials = false;
        this.mercadoPagoConfiguration = docSnap.data();
      } else {
        this.noCredentials = true;
      }
    },
    async initCheckOut(amount, maxInstallments) {
      


      const settings = {
        initialization: {
              amount: parseFloat(amount),
          payer: {
            name: this.$store.state.Auth.token.claims.name,
            email: this.$store.state.Auth.token.claims.email,
            identification: {
              type: 'CI',
              number: this.$store.state.Auth.token.claims.user_id,
            }

          },
        },
        customization: {
          locale: 'es-UY',
        },
        callbacks: {
          onReady: () => {
            // callback llamado cuando Brick esté listo
            console.log('Brick is ready')
          },
          onSubmit: async (cardFormData) => {

            try {
              this.loadingPayment = true
              const functions = getFunctions();
              const capturePayment = httpsCallable(functions, 'capturePayment');
              const result = await capturePayment({
                cardFormData: cardFormData,
                subscription: {
                  userid: this.$store.state.Auth.token.claims.user_id,
                  amount: amount,
                  days: this.selectedDays,
                  months: this.selectedMonths,
                  installments: this.installments
                }
              })

              if (result.data.status === 'approved') {
                this.$notify({
                  title: 'Pago Aprobado',
                  text: 'Tu pago ha sido aprobado.',
                  type: 'success'
                });
                this.$router.push('/perfil')
              } else if (result.data.status === 'in_process') {
                this.$notify({
                  title: 'Pago Pendiente',
                  text: 'Tu pago está pendiente.',
                  type: 'warning'
                });
                this.$router.push('/pendiente')

              }


            } catch (e) {
              this.$notify({
                title: 'Pago Fallido',
                text: 'Tu pago ha sido rechazado.',
                type: 'error'
              });
              this.$router.push('/fallido')
            } finally {
              this.loadingPayment = false
            }

          },
          onError: (error) => {
            console.log(error);
          },
        },
      };

      window.cardPaymentBrickController = await this.bricksBuilder.create('cardPayment', 'cardPaymentBrick_container', settings);


      this.$vuetify.goTo('#step2');


    },
    confirmSelection() {

      if (!this.$refs.form.validate()) {

        if (this.selectedMonths == 1) {
          this.$notify({
            title: 'Error',
            text: 'Debes seleccionar tu plan.',
            type: 'error'
          });
          return
        } else {
          this.$notify({
            title: 'Error',
            text: 'Debes seleccionar tu plan y la cantidad de cuotas.',
            type: 'error'
          });
          return
        }
      }

      const amount = this.subscription.priceMatrix.find(row => row[0].months === this.selectedMonths)[this.selectedDays].price;
      const maxInstallments = this.selectedMonths;

      this.e6 = 2;

      this.initCheckOut(amount, maxInstallments);
    },
    checkIfDisplay(item, day, index) {
      // iterate the price matrix and check if there is a price value for the given day return true
      return item.priceMatrix.some(row => row[index + 1].price > 0);
    },
    fromFirestore(serializedSubscription) {
      const deserializedMatrix = serializedSubscription.priceMatrix.map(row => [
        { months: row.months },
        ...row.prices.map(price => ({ price }))
      ]);
      return { ...serializedSubscription, priceMatrix: deserializedMatrix };
    },
    async fetchSubscriptionsForUserType() {

      try {
        this.loading = true;
        const db = getFirestore();

        const querySnapshot = await getDocs(query(collection(db, "subscriptions"), where("userType", "==", 'usuario' /* this.$store.state.Auth.token.claims.type */)));


        this.subscriptions = querySnapshot.docs.map(doc => {
          const data = doc.data();
          return { ...this.fromFirestore(data), id: doc.id };
        });

        this.subscription = this.subscriptions[0];

        ///set installments items using the value of the first element of the price matrix and dont add intermedial values

        this.subscription.priceMatrix.forEach(row => {
          this.installmentsItems.push(row[0].months)
        })

        this.loading = false;
      } catch (e) {
        this.$router.push('/fallido')
      }
    },
    goBack() {
      //go previous location

      this.$router.go(-1);
    },
    selectDaysAndMonths(cellIndex, rowIndex) {
      this.selectedDays = cellIndex + 1;
      this.selectedMonths = this.subscription.priceMatrix[rowIndex][0].months;
      //this.installments = this.selectedMonths
    },
    updateInstallments() {
      this.installments = this.selectedMonths
    }
  },
};
</script>

<style scoped>
.theme--dark.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: none !important;
}

.active {
  background: var(--v-primary-darken2) !important;
  color: white !important;
}
</style>
